import { Vue, Component, Watch } from 'vue-property-decorator'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
  @Component({
    name: 'GtrGeneralSurveyView',
    computed: {
      ...mapState('surveys', ['generalChangelog', 'currentlyGeneralDeployedLiveUUID', 'currentGeneralDevUUID', 'generalSurvey'])
    },
    components: {
      changelog: ChangeLog
    }
  })
export default class GtrGeneralSurveyView extends Vue {
  data () {
    return {
      language: 'en',
      loading: false,
      table: {
        headers: [
          { text: 'Sort', value: 'order' },
          { text: 'Question text', value: 'question_text' },
          { text: 'Applies To', value: 'applies_to' },
          { text: 'Required', value: 'required' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      changeLog: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      showAddGeneralQuestionForm: false,
      editing: false,
      questionModel: {
        id: Vue.prototype.$uuid.v4(),
        type: 'text',
        rate: 0,
        isQuiz: 0,
        range: { start: 0, end: 0, other: [] },
        required: 1,
        label: {},
        storage_type: 'text',
        show_if: {
          global_type: '*',
          type: '*',
          field: '',
          operator: '',
          group_operator: '',
          group_items: [],
          value: ''
        },
        speaker_question: 0,
        correct_answer: null
      },
      choices: {
        languages: [],
        questions_type: [
          {
            text: 'Text Field',
            value: 'text'
          },
          {
            text: 'Radio Buttons',
            value: 'radio'
          },
          {
            text: 'Checkboxes',
            value: 'checkbox'
          },
          {
            text: 'Select',
            value: 'select'
          }],
        rated: [{
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }]
      },
      tableUpdated: false,
      surveyVersion: null
    }
  }

  created () {
    this.$bus.$on('gtr-fetch-general-survey', this.fetchData)
  }

  beforeRouteLeave (from, to, next) {
    if (this.$data.tableUpdated) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  async mounted () {
    await this.fetchData()
  }

  @Watch('generalSurvey')
  onGeneralSurveyChange (payload: any) {
    if (payload.page_data) {
      if (payload.page_data[0].required_percentage) {
        this.$data.required_percentage = payload.page_data[0].required_percentage
      }
      this.$data.table.items = payload.page_data[0].fields
      this.$data.table.items.forEach(question => {
        if (!question.range) {
          question.range = { start: 0, end: 0, other: [] }
        }
      })
    } else {
      this.$data.table.items = []
    }
  }

    @Watch('generalChangelog')
  onGeneralChangelogChange (payload: any) {
    if (payload.data) {
      this.$data.changeLog.changeLog = payload.data
    }
  }

    @Watch('currentGeneralDevUUID')
    onCurrentGeneralDevUUIDChange (payload: any) {
      if (payload.dev) {
        this.$data.changeLog.currentDevUUID = payload.dev.uuid
      }
    }

    @Watch('currentlyGeneralDeployedLiveUUID')
    onCurrentlyGeneralDeployedLiveUUID (payload: any) {
      if (payload.live) {
        this.$data.changeLog.currentlyDeployedLiveUUID = payload.live.uuid
      }
    }

    @Watch('surveyVersion')
    async onSurveyVersionChange (payload: any) {
      if (payload !== 'default') {
        const response = await this.$store.dispatch('surveys/getSessionSurveyByUUID', { event_uuid: this.$route.params.event_uuid, survey_uuid: payload })
        const changeLogPageData = response.data.page_data[0]
        if (changeLogPageData) {
          this.$data.table.items = changeLogPageData.fields
          if (changeLogPageData.required_percentage) {
            this.$data.required_percentage = changeLogPageData.required_percentage
          }
          this.$data.changeLog.currentDevUUID = payload
        }
        Container.get(Notification).success('Survey version successfully changed.')
      }
    }

    @Watch('questionModel.type')
    onQuestionTypeChange (type: string) {
      if (type === 'checkbox') {
        this.$data.questionModel.correct_answer = []
      } else {
        this.$data.questionModel.correct_answer = null
      }
    }

    private async fetchGeneralChangelog () {
      try {
        this.$data.loading = true
        await this.$store.dispatch('surveys/getGeneralChangelog', { event_uuid: this.$route.params.event_uuid })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    private async fetchCurrentGeneralDevUUID () {
      try {
        this.$data.loading = true
        await this.$store.dispatch('surveys/getCurrentGeneralDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'general' })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    private async fetchCurrentlyGeneralDeployedLiveUUID () {
      try {
        this.$data.loading = true
        await this.$store.dispatch('surveys/getCurrentlyGeneralDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'general' })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    private async fetchGeneralSurvey () {
      try {
        this.$data.loading = true
        await this.$store.dispatch('surveys/getGeneralSurvey', { event_uuid: this.$route.params.event_uuid })
      } catch (error) {
        if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
          Container.get(ErrorHandlerService).error(error)
        }
      } finally {
        this.$data.loading = false
      }
    }

    async fetchData () {
      await this.fetchGeneralChangelog()
      await this.fetchCurrentGeneralDevUUID()
      await this.fetchCurrentlyGeneralDeployedLiveUUID()
      await this.fetchGeneralSurvey()
    }

    handleAddOption () {
      this.$data.questionModel.range.other.push('')
    }

    handleDeleteOption (index) {
      this.$data.questionModel.range.other.splice(index, 1)
    }

    handleEditQuestion (question) {
      this.$data.questionModel = JSON.parse(JSON.stringify(question))
      this.$data.showAddGeneralQuestionForm = true
      this.$data.editing = true
    }

    private handleShowAddGeneralQuestionForm () {
      this.$data.showAddGeneralQuestionForm = true
    }

    private handleCloseAddGeneralQuestionForm () {
      this.$data.showAddGeneralQuestionForm = false
    }

    handleDeleteQuestion (payload: any) {
      if (payload) {
        this.$data.table.items = this.$data.table.items.filter((question: any) => payload.id !== question.id)
        this.$data.tableUpdated = true
      }
    }

    handleTableUpdate (data: any) {
      this.$data.table.items = data
      this.$data.tableUpdated = true
    }

    updateSurveyVersion (uuid: string) {
      this.$data.surveyVersion = uuid
    }

    cancel () {
      this.$data.showAddGeneralQuestionForm = false
      this.cleanForm()
      this.$data.editing = false
    }

    async discardChanges () {
      await this.fetchGeneralSurvey()
      this.$data.tableUpdated = false
    }

    saveQuestion () {
      if (this.$data.editing) {
        const activeQuestionIndex = this.$data.table.items.findIndex(question => question.id === this.$data.questionModel.id)
        if (activeQuestionIndex >= 0) {
          this.$data.table.items[activeQuestionIndex] = this.$data.questionModel
        }
      } else {
        this.$data.table.items.push(this.$data.questionModel)
      }

      this.$data.showAddGeneralQuestionForm = false
      this.$data.tableUpdated = true
      this.$data.editing = false
      this.cleanForm()
    }

    async save () {
      try {
        this.$data.loading = true
        const fields: any = this.$data.table.items.map(obj => ({ ...obj }))
        fields.map(field => {
          if (!field.range.start && !field.range.end && field.range.other.length === 0) {
            delete field.range
          }
          if (field.correct_answer) {
            delete field.rate
          } else {
            delete field.correct_answer
          }
          return field
        })
        const data = [{
          page_type: 'STANDARD',
          prefix: '',
          suffix: '',
          settings: {},
          required_percentage: null,
          fields: fields
        }]

        if (this.$data.required_percentage) {
          data[0].required_percentage = this.$data.required_percentage
        } else {
          delete data[0].required_percentage
        }

        await this.$store.dispatch('surveys/saveGeneralSurvey', {
          event_uuid: this.$route.params.event_uuid,
          type: 'survey',
          sub_type: 'general',
          data: data
        })
        Container.get(Notification).success('Survey successfully saved.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
        this.$data.tableUpdated = false
        this.$data.editing = false
      }
    }

	  cleanForm () {
      this.$data.questionModel = {
        id: Vue.prototype.$uuid.v4(),
        type: 'text',
        rate: 0,
        range: { start: 0, end: 0, other: [] },
        required: 1,
        label: {},
        storage_type: 'text',
        show_if: {},
        speaker_question: 0,
        session_uuid: null,
        correct_answer: null,
        isQuiz: 0
      }
    }

    onChange (e: any) {
      if (e) {
        this.$data.questionModel.isQuiz = 1
        this.$data.questionModel.rate = false
        this.$data.questionModel.range.start = 0
        this.$data.questionModel.range.end = 0
      } else {
        this.$data.questionModel.isQuiz = 0
        this.$data.questionModel.correct_answer = null
      }
    }

    async handleDeployed () {
      await this.fetchGeneralChangelog()
      await this.fetchCurrentGeneralDevUUID()
      await this.fetchCurrentlyGeneralDeployedLiveUUID()
    }
}
